import { React } from "react";
import { NumericFormat } from "react-number-format";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import AssetSummary from "./AssetSummary";
import PriceSummary from "./PriceSummary";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReviseFundModal } from "./ReviseFundModal";
import { confirmAlert } from "react-confirm-alert";
import useTransactionDetails from "../hooks/useTransactionDetails";
import { useAuth } from "react-oidc-context";

const ValuationAssetRow = ({ designation, wrapper, product, contract, asset }) => {


    const auth = useAuth();
    const { user: { profile: { roles } } } = auth;
    const canEdit = roles.includes("transaction_write");

    const { reviseTransaction } = useTransactionDetails();

    const getNumberOfDays = (days) => {
        if (days <= 31) return days.toFixed(0) + " days";

        if (days > 31 && days < 365)
            return Math.round(days * 0.032855).toFixed(0) + " months";

        return (days / 365).toFixed(1) + " years";
    };

    const showPriceDate = (asset) => {

        var priceWarning = asset.warnings.some(x => x.warning == 0);
        
        if(asset?.assetPrice?.priceDate != null){

            if(priceWarning) {

                var today = new Date();
                var differenceInDays = Math.round((today.getTime() - new Date(asset?.assetPrice?.priceDate).getTime()) / (1000*3600*24));

                return  <OverlayTrigger
                placement="top"
                overlay={
                    <Tooltip>
                        <div>
                            <b>Price shown might be out of date</b>
                            <br />
                            <b>Price is {getNumberOfDays(differenceInDays)} old</b>
                        </div>
                    </Tooltip>
                }
            >
                <span style={{color: "red"}}>{
            new Date(
                asset?.assetPrice?.priceDate
            ).toLocaleDateString("en-GB")}
            </span>
            </OverlayTrigger>
            }

            return <span>{
                new Date(
                    asset?.assetPrice?.priceDate
                ).toLocaleDateString("en-GB")}
            </span>
        }

        return "-";

    }

    const getAverageLength = (days) => {
        if (days <= 31) return days.toFixed(0) + " days";

        if (days > 31 && days < 365)
            return Math.round(days * 0.032855).toFixed(0) + " months";

        return (days / 365).toFixed(1) + " years";
    };

    const handleConfirm = (request, asset, contractReference) => {
        request = {
            masterAccountId: designation?.designation?.masterAccountId,
            designationId: designation.designationId,
            wrapperId: wrapper.wrapperId,
            productId: product.productId,
            providerId: product.provider?.providerId,
            custodianId: asset?.custodianId,
            assetId: asset?.asset?.assetId,
            custodianReference: contractReference,
            providerReference: contractReference,
            ...request,
        };

        reviseTransaction(request);
    };

    const onReviseClicked = (asset, contractReference) => {

        confirmAlert({
            closeOnEscape: true,
            closeOnClickOutside: true,
            customUI: (props) => {
                return (
                    <ReviseFundModal
                        handleConfirm={(request) =>
                            handleConfirm(request, asset, contractReference)
                        }
                        asset={asset}
                        {...props}
                    />
                );
            },
        });
    };

    if (asset.priceIndex == false) {
        return (
            <div className="row mb-2" key={asset.assetId}>
                <div className="col">
                    <span style={{ fontWeight: "bold" }}>
                        {contract.contractReference}
                    </span>
                </div>
                <div className="col-3"><span style={{fontWeight: "bold"}}>{asset.assetDisplayName}</span></div>
                <div className="col">
                </div>
                <div style={{ textAlign: "right" }} className="col">
                </div>
                <div style={{ textAlign: "right" }} className="col">
                </div>
                <div style={{ textAlign: "right" }} className="col">
                </div>
                <div style={{ textAlign: "right" }} className="col">
                    <span>
                        <NumericFormat
                            value={asset.totalValue.toFixed(2)}
                            thousandSeparator={true}
                            decimalScale={0}
                            displayType="text"
                            prefix={"£"}
                        />
                    </span>
                </div>
                <div style={{ textAlign: "right" }} className="col">
                   
                </div>
                <div style={{ textAlign: "right" }} className="col">
                   
                </div>
                <div style={{ textAlign: "right" }} className="col">
                </div>
                {canEdit && <div className="col-md-auto" style={{ textAlign: "center" }}>
                    <FontAwesomeIcon
                        style={{ minWidth: "67px" }}
                        icon="fa-refresh"
                        size="sm"
                        onClick={() =>
                            onReviseClicked(asset, contract.contractReference)
                        }
                    />
                </div>}
            </div>
        );
    }

    return (
        <div className="row mb-2" key={asset.assetId}>
            <div className="col">
                <span style={{ fontWeight: "bold" }}>
                    {contract.contractReference}
                </span>
            </div>
            <div className="col-3">
                <AssetSummary asset={asset} />
            </div>
            <div className="col">
                <span>{showPriceDate(asset)}</span>
            </div>
            <div style={{ textAlign: "right" }} className="col">
                <PriceSummary asset={asset} />
            </div>
            <div style={{ textAlign: "right" }} className="col">
                <span> <NumericFormat
                        value={asset.totalUnits.toFixed(4)}
                        thousandSeparator={true}
                        displayType="text"
                        decimalScale={4}
                        prefix={""}
                    />
                </span>
            </div>
            <div style={{ textAlign: "right" }} className="col">
            <span style={{color: asset.warnings.some(x => x.warning == 1) ? "#e0c200" : "black" }}>
                    <NumericFormat
                        value={asset.totalInvested.toFixed(2)}
                        thousandSeparator={true}
                        displayType="text"
                        decimalScale={0}
                        prefix={"£"}
                    />
                </span>
            </div>
            <div style={{ textAlign: "right" }} className="col">
                <span style={{color: asset.warnings.some(x => x.warning == 1) ? "#e0c200" : "black" }}>
                    <NumericFormat
                        value={asset.totalValue.toFixed(2)}
                        thousandSeparator={true}
                        decimalScale={0}
                        displayType="text"
                        prefix={"£"}
                    />
                </span>
            </div>
            <div style={{ textAlign: "right" }} className="col">
                <span
                    style={
                        asset.gainPercent < 0
                            ? { color: "red" }
                            : { color: "green" }
                    }
                >
                    <NumericFormat
                        value={asset.gainPercent.toFixed(1)}
                        thousandSeparator={true}
                        displayType="text"
                        decimalScale={2}
                        suffix={"%"}
                    />
                </span>
            </div>
            <div style={{ textAlign: "right" }} className="col">
                {asset.averageDaysHeld > 180 &&
                    <span
                        style={
                            asset.totalAnnualisedReturn < 0
                            ? { color: "red" }
                            : { color: "green" }
                        }
                        >
                        <NumericFormat
                            value={asset.totalAnnualisedReturn.toFixed(2)}
                            thousandSeparator={true}
                            displayType="text"
                            decimalScale={1}
                            suffix={"% pa"}
                            />
                    </span>
                }
            </div>
            <div style={{ textAlign: "right" }} className="col">
                <span>{getAverageLength(asset.averageDaysHeld)}</span>
            </div>
            {canEdit &&
            <div className="col-md-auto" style={{ textAlign: "center" }}>
                <FontAwesomeIcon
                    style={{ minWidth: "67px" }}
                    icon="fa-refresh"
                    size="sm"
                    onClick={() =>
                        onReviseClicked(asset, contract.contractReference)
                    }
                />
            </div>}
        </div>
    );
};

export default ValuationAssetRow;
