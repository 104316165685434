import React, { useState } from "react";
import { Button } from "react-bootstrap";
import toast from "react-hot-toast";
import Skeleton from "react-loading-skeleton";
import {
    ErrorCodeDisplay,
    ErrorMessage,
    ErrorMessageWrapper,
} from "../../../components/messages";
import { ConfirmModal } from "../../../components/modals";
import useClientPreferences from "../hooks/useClientPreferences";
import useClientServiceTerms from "../hooks/useClientServiceTerms";
import useReviews from "../hooks/useReviews";
import {
    DateInput,
    FormCheck,
    FormSelect,
    FormTextArea,
    NumberInput
} from "./../../../components/forms/";
import GenericAdviserSelect from "./../../../components/forms/selects/GenericAdviserSelect";
import CenterFormColumnComponent from "./CenterFormColumnComponent";
import PortfolioBreakdown from "./PortfolioBreakdown";
import ServiceAgreementProofs from "./ServiceAgreementProofs";
import { BasicToast } from "../../../components/toasts";

const ServiceAgreementDetails = ({ masterAccountId, loadingLines = 3 }) => {
    const {
        clientServiceTerms,
        isLoading,
        isError,
        error,
        reload,
        serviceLevelAgreements,
        updateClientServiceAgreement,
        createSLA,
    } = useClientServiceTerms(masterAccountId);

    const { 
        servicePreferences,
        servicePreferencesError,
        servicePreferencesIsError,
        servicePreferencesIsErrorUpdating,
        servicePreferencesIsLoading,
        servicePreferencesIsUpdating,
        reloadServicePreferences,
        servicePreferencesErrorUpdateError,
        updateServicePreference
    } = useClientPreferences(masterAccountId);

    const { createReview } = useReviews(masterAccountId);

    const handleRetry = (e) => {
        e.preventDefault();
        reload(e);
    };

    const onFieldChanged = (clientServiceAgreementId, property, value) =>
        updateClientServiceAgreement(
            clientServiceTerms.clientAgreementId,
            property,
            value
        );

    const onPreferencesFieldChanged = (property, value) => updateServicePreference(property, value);

    const months = [
        { label: "January", value: 1, maxDays: 31 },
        { label: "February", value: 2, maxDays: 28 },
        { label: "March", value: 3, maxDays: 31 },
        { label: "April", value: 4, maxDays: 30 },
        { label: "May", value: 5, maxDays: 31 },
        { label: "June", value: 6, maxDays: 30 },
        { label: "July", value: 7, maxDays: 31 },
        { label: "August", value: 8, maxDays: 31 },
        { label: "September", value: 9, maxDays: 30 },
        { label: "October", value: 10, maxDays: 31 },
        { label: "November", value: 11, maxDays: 30 },
        { label: "December", value: 12, maxDays: 31 },
    ];   
    
    const excludedDates = [new Date(2024, 1, 29), new Date(2028, 1, 29), new Date(2032, 1, 29), new Date(2036, 1, 29), new Date(2040, 1, 29), new Date(2044, 1, 29)]; 

    const onAnniversaryMonthChanged = (selection) => {
        if (clientServiceTerms.anniversaryDay > selection.maxDays) {
            onFieldChanged(
                clientServiceTerms.clientAgreementId,
                "anniversaryDay",
                selection.maxDays
            );
        }

        onFieldChanged(
            clientServiceTerms.clientAgreementId,
            "anniversaryMonth",
            selection.value
        );
    };

    const onClientSLAStatusChanges = (selection) => {
        var errors = [];

        if(selection == 1){
            //do some validation
            if(clientServiceTerms.initialReviewDate == null){
                errors.push("Initial Review Date");
            }
        }


        if(errors.length > 0){

            toast.custom((t) => <BasicToast 
                control={t}
                icon="fa-solid fa-user-plus" 
                title="Cannot set terms to operational" 
                message={errors.join(' ')}
            />, { duration: 8000 });

            return;

        }

         onFieldChanged(
            clientServiceTerms.clientAgreementId,
            "status",
            selection
        )
    }
    
    const onCreatePressed = (e) => createSLA(e);

    // const onCreateReviewPressed = (event) => {
    //     createReview(event).then(
    //         (r) => {
    //             toast.custom(
    //                 (t) => (
    //                     <BasicToast
    //                         control={t}
    //                         date={r?.createdDate}
    //                         icon="fa-solid fa-file-word"
    //                         title="Review Generated"
    //                         action={() => {
    //                             toast.dismiss(t.id);
    //                         }}
    //                     />
    //                 ),
    //                 { duration: Infinity }
    //             );
    //         },
    //         (e) => toast.error(e?.data)
    //     );
    // };

    const onCreateReviewPressed = (event) => {
        toast.promise(createReview(event), {
            loading: `Creating Review...`,
            success: (data) => {
                return `Review Created`;
            },
            error: (err) => <div>{err?.data?.message}</div>
        });
    };

    const [isConfirmationShown, setIsConfirmationShown] = useState(false);
    const [updatedTermsValue, setUpdatedTermsValue] = useState(null);

    const onServiceTermsChanged = () => {
        updateClientServiceAgreement(
            clientServiceTerms.clientAgreementId,
            "serviceLevelAgreementId",
            updatedTermsValue
        );

        setIsConfirmationShown(false);
        setUpdatedTermsValue(null);
    };

    if (error || isError === true) {
        return (
            <ErrorMessageWrapper margin="0px auto" maxWidth="500px">
                <ErrorMessage error={error} retryCallback={handleRetry} />
            </ErrorMessageWrapper>
        );
    }

    return (
        <div>
            <ConfirmModal
                title="Updating service terms"
                message="Updating the service terms will override some of the options on this page.  Are you sure you want to update this?"
                handleConfirm={() => onServiceTermsChanged()}
                handleClose={() => setIsConfirmationShown(false)}
                show={isConfirmationShown}
            />
            {isLoading === true && <Skeleton count={loadingLines} />}
            {!isLoading && Object.keys(clientServiceTerms).length === 0 && (
                <div className="row mb-2">
                    <div className="col">
                        There is no active client agreements available. You can
                        click{" "}
                        <a href="#" onClick={onCreatePressed}>
                            here
                        </a>{" "}
                        to create one.
                    </div>
                </div>
            )}
            {!isLoading && Object.keys(clientServiceTerms).length > 0 && (
                <div>
                    <div className="row mb-2">
                        <div className="col">
                            <FormSelect
                                label="Terms"
                                options={serviceLevelAgreements}
                                defaultValue={
                                    clientServiceTerms?.serviceLevelAgreementId
                                }
                                onBlur={(selection) => {
                                    if (
                                        clientServiceTerms.feeAgreements
                                            ?.length > 0
                                    ) {
                                        setUpdatedTermsValue(selection.value);
                                        setIsConfirmationShown(true);
                                    } else {
                                        onFieldChanged(
                                            clientServiceTerms.clientAgreementId,
                                            "serviceLevelAgreementId",
                                            selection.value
                                        );
                                    }
                                }}
                            />
                        </div>
                        <div className="col">
                            <FormSelect
                                label="Frequency"
                                defaultValue={
                                    clientServiceTerms?.reviewFrequency
                                }
                                options={[
                                    { label: "Annually", value: 12 },
                                    { label: "6 Monthly", value: 6 },
                                    { label: "3 Monthly", value: 3 },
                                    { label: "Biennially", value: 24 }
                                ]}
                                onBlur={(selection) => {
                                        onFieldChanged(
                                            clientServiceTerms.clientAgreementId,
                                            "reviewFrequency",
                                            selection.value
                                        );
                                }}
                            />
                        </div>
                        <div className="col-md-auto">
                            <FormCheck
                                type="checkbox"
                                label="Transaction Fees"
                                name="hasTransactionFees"
                                vertical={true}
                                labelFirst={true}
                                className="mt-3"
                                isChecked={
                                    clientServiceTerms.doTransactionFeesApply
                                }
                                onChange={() =>
                                    onFieldChanged(
                                        clientServiceTerms.clientAgreementId,
                                        "doTransactionFeesApply",
                                        !clientServiceTerms.doTransactionFeesApply
                                    )
                                }
                            />
                        </div>
                        <div className="col-md-auto">
                            <FormCheck
                                type="checkbox"
                                label="Drawdown Review"
                                name="isDrawdownReview"
                                vertical={true}
                                labelFirst={true}
                                className="mt-3"
                                isChecked={
                                    clientServiceTerms.incomeDrawdownOption
                                }
                                onChange={() =>
                                    onFieldChanged(
                                        clientServiceTerms.clientAgreementId,
                                        "incomeDrawdownOption",
                                        !clientServiceTerms.incomeDrawdownOption
                                    )
                                }
                            />
                        </div>
                        <div className="col-3">
                            <FormSelect
                                label="Status"
                                options={[
                                    {
                                        label: "Drafting New Terms",
                                        value: 0,
                                        isDisabled: false
                                    },
                                    {
                                        label: "Awaiting Signed Consent",
                                        value: 2,
                                    },
                                    {
                                        label: "Awaiting Service Fees Update",
                                        value: 3,
                                    },
                                    {
                                        label: "Service Agreement Operational",
                                        value: 1,
                                    },
                                    {
                                        label: "Service Agreement Terminated",
                                        value: 4,
                                    },
                                    {
                                        label: "Not Taken Up",
                                        value: 5,
                                    },
                                    {
                                        label: "Deceased Account",
                                        value: 6,
                                    },
                                    {
                                        label: "Fee Collection Paused",
                                        value: 7,
                                    },
                                ]}
                                defaultValue={clientServiceTerms.status}
                                onBlur={(selection) =>
                                    onClientSLAStatusChanges(selection.value)
                                    // onFieldChanged(
                                    //     clientServiceTerms.clientAgreementId,
                                    //     "status",
                                    //     selection.value
                                    // )
                                }
                            />
                        </div>
                        <div className="col-2">
                            <DateInput
                                label="Status Date"
                                value={clientServiceTerms.statusDate}
                                onBlur={(value) =>
                                    onFieldChanged(
                                        clientServiceTerms.clientAgreementId,
                                        "statusDate",
                                        value
                                    )
                                }
                            />
                        </div>
                        <div className="col-md-auto">
                            <FormCheck
                                type="checkbox"
                                label="Terms Apply"
                                name="termsApply"
                                vertical={true}
                                labelFirst={true}
                                className="mt-3"
                                disabled={true}
                                isChecked={
                                    clientServiceTerms.existingTermsApply
                                }
                                onChange={() =>
                                    onFieldChanged(
                                        clientServiceTerms.clientAgreementId,
                                        "existingTermsApply",
                                        !clientServiceTerms.existingTermsApply
                                    )
                                }
                            />
                        </div>
                    </div>
                    {!servicePreferencesIsError &&(
                        <div className="row mb-2">
                            <div className="col">
                                <GenericAdviserSelect
                                    label="Review Adviser"
                                    placeholder="Review Adviser"
                                    defaultValue={servicePreferences?.reviewAdviserId}
                                    onBlur={(values) =>
                                        onPreferencesFieldChanged(
                                            "reviewAdviserId",
                                            values.value
                                        )
                                    }
                                />
                            </div>
                            <div className="col">
                                <FormSelect
                                    label="Appointment Style"
                                    defaultValue={servicePreferences?.appointmentStyle}
                                    options={[
                                        { label: "Home Meeting", value: 0 },
                                        { label: "Office Meeting", value: 1 },
                                        { label: "Phone Call", value: 2 },
                                        { label: "Video Meeting", value: 3 },
                                        {
                                            label: "No Appointment Required",
                                            value: 4,
                                        },
                                    ]}
                                    onBlur={(values) =>
                                        onPreferencesFieldChanged(
                                            "appointmentStyle",
                                            values.value
                                        )
                                    }
                                />
                            </div>
                            <div className="col">
                                <FormSelect
                                    label="Scheduling"
                                    defaultValue={servicePreferences?.appointmentSchedulingType}
                                    options={[
                                        { label: "Automated", value: 0 },
                                        { label: "Manual", value: 1 },
                                        {
                                            label: "No Scheduling Required",
                                            value: 2,
                                        },
                                    ]}
                                    onBlur={(values) =>
                                        onPreferencesFieldChanged(
                                            "appointmentSchedulingType",
                                            values.value
                                        )
                                    }
                                />
                            </div>
                        </div>
                    )}
                    {servicePreferencesIsError && (
                        <div className="row mb-2">
                        <ErrorMessageWrapper margin="0px auto" maxWidth="500px">
                            <ErrorMessage error={servicePreferencesError} retryCallback={reloadServicePreferences} />
                        </ErrorMessageWrapper>
                        </div>
                    )}
                    <div className="row mb-2">
                        <div className="col-2"> 
                            <DateInput 
                                label="Initial Review Date" 
                                value={clientServiceTerms.initialReviewDate} 
                                onBlur={(value) => 
                                    onFieldChanged( 
                                        clientServiceTerms.clientAgreementId, 
                                        "initialReviewDate", 
                                        value 
                                    ) 
                                } 
                                excludeDates={excludedDates} 
                                minDate={new Date()} 
                                isClearable
                            /> 
                        </div> 
                        <div className="col-2">
                            <FormSelect
                                label="Anniversary"
                                options={months}
                                defaultValue={
                                    clientServiceTerms.anniversaryMonth
                                }
                                onBlur={(selection) =>
                                    onAnniversaryMonthChanged(selection)
                                }
                            />
                        </div>
                        <CenterFormColumnComponent className="col-1">
                            <NumberInput
                                value={clientServiceTerms.anniversaryDay}
                                onBlur={(value) =>
                                    onFieldChanged(
                                        clientServiceTerms.clientAgreementId,
                                        "anniversaryDay",
                                        value
                                    )
                                }
                                suffix={""}
                                isAllowed={(values) => {
                                    if (!values.value) return true;
                                    const { floatValue } = values;
                                    return (
                                        floatValue <=
                                        months.find(
                                            (x) =>
                                                x.value ==
                                                clientServiceTerms.anniversaryMonth
                                        ).maxDays
                                    );
                                }}
                                decimalScale={0}
                            />
                        </CenterFormColumnComponent>

                        <div className="col-3">
                            <FormSelect
                                label="Adviser Updates"
                                options={[
                                    { label: "No Updates", value: 0 },
                                    { label: "3 Monthly", value: 1 },
                                    { label: "6 Monthly", value: 2 },
                                ]}
                                defaultValue={
                                    clientServiceTerms.adviserUpdateFrequency
                                }
                                onBlur={(selection) =>
                                    onFieldChanged(
                                        clientServiceTerms.clientAgreementId,
                                        "adviserUpdateFrequency",
                                        selection.value
                                    )
                                }
                            />
                        </div>
                        {/* <CenterFormColumnComponent className="col-2">
                            <Button
                                variant="primary"
                                className="d-block w-75"
                                onClick={(e) => onCreateReviewPressed(e)}
                            >
                                Create Review
                            </Button>
                        </CenterFormColumnComponent> */}
                    </div>

                    <PortfolioBreakdown
                        masterAccountId={masterAccountId}
                        clientServiceAgreementId={
                            clientServiceTerms.clientAgreementId
                        }
                        feeBreakdowns={clientServiceTerms?.feeBreakdowns}
                    />

                    <div className="row mb-4">
                        <div className="col">
                            <FormTextArea
                                label="Special Instructions"
                                value={clientServiceTerms.specialInstructions}
                                onBlur={(value) =>
                                    onFieldChanged(
                                        clientServiceTerms.clientAgreementId,
                                        "specialInstructions",
                                        value
                                    )
                                }
                                minRows={3}
                                maxRows={3}
                            />
                        </div>
                    </div>

                    <ServiceAgreementProofs
                        masterAccountId={masterAccountId}
                        clientServiceAgreementId={
                            clientServiceTerms.clientAgreementId
                        }
                        feeAgreements={clientServiceTerms.feeAgreements}
                        feeSignatories={clientServiceTerms.feeSignatories}
                    />
                </div>
            )}
        </div>
    );
};

export default ServiceAgreementDetails;
